import React from "react";

const Blogs = ({ data }) => {
  return (
    <div id="blogs" className="container mx-auto px-8 md:px-16 my-12">
      <div className="w-full flex items-center justify-between flex-col md:flex-row gap-2 md:gap-0 mb-8">
        <p className="font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl text-white text-cente">
          المقالات
        </p>
        <button className="bg-purpleColor text-white rounded-md flex items-center justify-center py-3 px-6">
          <a href="/#">مشاهدة المزيد</a>
        </button>
      </div>
      <div>
        {data.map((item, index) => (
          <div
            key={index}
            className={`flex items-center gap-6 md:gap-12 flex-col mb-5 ${
              index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
            }`}
          >
            <div className="md:w-1/2">
              <p className="font-bold text-white text-lg md:text-xl lg:text-2xl mb-3">
                {item.title}
              </p>
              <p className="text-gray-400 text-sm mb-3">
                {item.desc.substr(0, 300)}...
              </p>
              <button>
                <a
                  href={item.path}
                  target="_blank"
                  rel="noreferrer"
                  className=" font-medium text-[#fcbd16]"
                >
                  مشاهدة المزيد
                </a>
              </button>
            </div>
            <div className="md:w-1/2">
              <img
                alt={item.title}
                src={item.img}
                className="w-full object-cover"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
