import team1 from "../assets/team-01.jpg";
import team2 from "../assets/team-02.jpg";
import team3 from "../assets/team-03.jpg";
import av from "../assets/av.jpg";
import { BsTools } from "react-icons/bs";
import { FaShippingFast } from "react-icons/fa";
import { MdSecurity } from "react-icons/md";
import { IoIosPricetag } from "react-icons/io";
import { GoArrowUpLeft } from "react-icons/go";
import { GiProfit } from "react-icons/gi";
import port1 from "../assets/port-01.jpg";
import port2 from "../assets/port-02.jpg";
import port3 from "../assets/port-03.jpg";
import port4 from "../assets/port-04.jpg";
import port5 from "../assets/port-05.jpg";
import port6 from "../assets/port-01.jpg";
import port7 from "../assets/port-02.jpg";
import port8 from "../assets/port-03.jpg";
import port9 from "../assets/port-04.jpg";
import port10 from "../assets/port-05.jpg";
import port11 from "../assets/port-01.jpg";
import port12 from "../assets/port-02.jpg";
import { TbMessagesOff } from "react-icons/tb";
import { MdSupport } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import blog1 from "../assets/case-01.jpg";
import blog2 from "../assets/case-02.jpg";
import blog3 from "../assets/case-03.jpg";
import blog4 from "../assets/case-04.jpg";
export const links = [
  {
    title: "الرئيسية",
    path: "#",
  },
  {
    title: "من نحن",
    path: "#about",
  },
  {
    title: "لماذا NEXT LEVEL ",
    path: "#feat",
  },

  {
    title: "أعمالنا",
    path: "#port",
  },
  {
    title: "الباقات",
    path: "#pack",
  },
  {
    title: "المقالات",
    path: "#blogs",
  },
  {
    title: "تواصل معنا",
    path: "#contact",
  },
  {
    title: "اراء العملاء",
    path: "#rev",
  },
];
export const team = [team1, team2, team3];
export const stats = [
  {
    bg: "#3f4ac8",
    number: 250,
    title: "عميل",
  },
  {
    bg: "#ff6f86",
    number: 10,
    title: "سنوات خبرة",
  },
  {
    bg: "#fcbd16",
    number: 450,
    title: "مشروع",
  },
  {
    bg: "#de54ff",
    number: 700,
    title: "شراكة ناجحة",
  },
];
export const featuers = [
  {
    bg: "#ebb630",
    img: <BsTools size={40} />,

    title: "أفضل ادوات التيكنلوجويا",
    desc: "وريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه … بروشور او فلاير على سبيل المثال … او نمامواقع انترنت … وعند موافقه العميل المبدئيه على التصميم يتم ازالة هذا النص من التصميم ويتم وضع النصوص النهائية المطلوبة",
  },
  {
    bg: "#3848b3",
    img: <FaShippingFast size={40} />,
    title: "نتيجة استجابة سريعة ",
    desc: "وريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه … بروشور او فلاير على سبيل المثال … او نمامواقع انترنت … وعند موافقه العميل المبدئيه على التصميم يتم ازالة هذا النص من التصميم ويتم وضع النصوص النهائية المطلوبة",
  },
  {
    bg: "#f3738c",
    img: <MdSecurity size={40} />,
    title: "أمن البيانات والخصوصية",
    desc: "وريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه … بروشور او فلاير على سبيل المثال … او نمامواقع انترنت … وعند موافقه العميل المبدئيه على التصميم يتم ازالة هذا النص من التصميم ويتم وضع النصوص النهائية المطلوبة",
  },

  {
    bg: "#d6d6d6",
    img: <IoIosPricetag size={40} />,
    title: "تسعير أكثر مرونة",
    desc: "وريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه … بروشور او فلاير على سبيل المثال … او نمامواقع انترنت … وعند موافقه العميل المبدئيه على التصميم يتم ازالة هذا النص من التصميم ويتم وضع النصوص النهائية المطلوبة",
  },
];
export const marketing = [
  {
    icon: <GoArrowUpLeft size={20} />,
    title: "اصعد بمبيعاتك للفضاء",
  },
  {
    icon: <GiProfit size={20} />,
    title: "ارباح تصل الي 500 % ",
  },
  {
    icon: <GoArrowUpLeft size={20} />,
    title: "اصعد بمبيعاتك للفضاء",
  },
  {
    icon: <GiProfit size={20} />,
    title: "ارباح تصل الي 500 % ",
  },
  {
    icon: <GoArrowUpLeft size={20} />,
    title: "اصعد بمبيعاتك للفضاء",
  },
  {
    icon: <GiProfit size={20} />,
    title: "ارباح تصل الي 500 % ",
  },
];
export const portfolio = [
  {
    img: port1,
    title: "الطنطاوي ستور",
  },
  {
    img: port2,
    title: "أباظا ستور",
  },
  {
    img: port3,
    title: "نجوان ستور",
  },
  {
    img: port4,
    title: "امازون ستور",
  },
  {
    img: port5,
    title: "علي بابا ستور",
  },
  {
    img: port6,
    title: "شي ان ستور",
  },
  {
    img: port7,
    title: "سميك ستور",
  },
  {
    img: port8,
    title: "الطنطاوي ستور",
  },
  {
    img: port9,
    title: "الطنطاوي ستور",
  },
  {
    img: port10,
    title: "الطنطاوي ستور",
  },
  {
    img: port11,
    title: "الطنطاوي ستور",
  },
  {
    img: port12,
    title: "الطنطاوي ستور",
  },
];
export const specialistDetails = [
  {
    icon: <TbMessagesOff size={30} />,
    title: "عمليات العمل الأولى",
    desc: "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم",
  },
  {
    icon: <FaUsers size={30} />,
    title: "فريق متخصص",
    desc: "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم",
  },
  {
    icon: <MdSupport size={30} />,
    title: "دعم 24 / 7",
    desc: "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم",
  },
];
export const packages = [
  {
    name: "شخصية",
    price: 50,
    details: [
      "تفاصيللا",
      "تفاصيللا",
      "تفاصيللا",
      "تفاصيللا",
      "تفاصيللا",
      "تفاصيللا",
    ],
    desc: "افضل باقة للعملا الكحيانين",
  },
  {
    name: "شخصية برو ماكس",
    price: 70,
    details: [
      "تفاصيللا",
      "تفاصيللا",
      "تفاصيللا",
      "تفاصيللا",
      "تفاصيللا",
      "تفاصيللا",
    ],
    desc: "افضل باقة للعملا الكحيانين برو ماكس",
  },
  {
    name: "شركات",
    price: 120,
    details: [
      "تفاصيللا",
      "تفاصيللا",
      "تفاصيللا",
      "تفاصيللا",
      "تفاصيللا",
      "تفاصيللا",
    ],
    desc: "افضل باقة للعملا الكحيانين برو ماكس",
  },
];
export const blogs = [
  {
    title: "احتياجات كبار السن النفسية",
    img: blog1,
    desc: "احتياجات كبار السن النفسية أمر يزداد البحث عنه بشكل مستمر من قبل العديد من الأفراد، حيث لا تتطلب رعاية كبار السن فقط اهتماماً بالجوانب الطبية والصحية، بل تمتد أيضًا للرعاية النفسية والاجتماعية، فكبار السن يواجهون تحديات عديدة تتعلق بالصحة النفسية نتيجة للتغيرات التي تحدث في حياتهم مثل الانفصال عن الأحباء، أو الإصابة بالأمراض المزمنة، أو فقدان الاستقلالية، ولذا فينبغي علينا فهم احتياجاتهم النفسية",
    path: "https://google.com",
  },
  {
    title: "احتياجات كبار السن النفسية",
    img: blog2,
    desc: "احتياجات كبار السن النفسية أمر يزداد البحث عنه بشكل مستمر من قبل العديد من الأفراد، حيث لا تتطلب رعاية كبار السن فقط اهتماماً بالجوانب الطبية والصحية، بل تمتد أيضًا للرعاية النفسية والاجتماعية، فكبار السن يواجهون تحديات عديدة تتعلق بالصحة النفسية نتيجة للتغيرات التي تحدث في حياتهم مثل الانفصال عن الأحباء، أو الإصابة بالأمراض المزمنة، أو فقدان الاستقلالية، ولذا فينبغي علينا فهم احتياجاتهم النفسية",
    path: "https://google.com",
  },
  {
    title: "احتياجات كبار السن النفسية",
    img: blog3,
    desc: "احتياجات كبار السن النفسية أمر يزداد البحث عنه بشكل مستمر من قبل العديد من الأفراد، حيث لا تتطلب رعاية كبار السن فقط اهتماماً بالجوانب الطبية والصحية، بل تمتد أيضًا للرعاية النفسية والاجتماعية، فكبار السن يواجهون تحديات عديدة تتعلق بالصحة النفسية نتيجة للتغيرات التي تحدث في حياتهم مثل الانفصال عن الأحباء، أو الإصابة بالأمراض المزمنة، أو فقدان الاستقلالية، ولذا فينبغي علينا فهم احتياجاتهم النفسية",
    path: "https://google.com",
  },
  {
    title: "احتياجات كبار السن النفسية",
    img: blog4,
    desc: "احتياجات كبار السن النفسية أمر يزداد البحث عنه بشكل مستمر من قبل العديد من الأفراد، حيث لا تتطلب رعاية كبار السن فقط اهتماماً بالجوانب الطبية والصحية، بل تمتد أيضًا للرعاية النفسية والاجتماعية، فكبار السن يواجهون تحديات عديدة تتعلق بالصحة النفسية نتيجة للتغيرات التي تحدث في حياتهم مثل الانفصال عن الأحباء، أو الإصابة بالأمراض المزمنة، أو فقدان الاستقلالية، ولذا فينبغي علينا فهم احتياجاتهم النفسية",
    path: "https://google.com",
  },
];
export const reviews = [
  {
    img: av,
    name: "ماجد الشافعي",
    rev: "نيسكت ليفل من افضل شركات مصر و بشمهندس أحمد سمير راجل جدع و رجولة و التيم مية مية احسن شغل و اعلي جودة ب افضل سعر و كله كويس",
  },
  {
    img: av,
    name: "ماجد الشافعي",
    rev: "نيسكت ليفل من افضل شركات مصر و بشمهندس أحمد سمير راجل جدع و رجولة و التيم مية مية احسن شغل و اعلي جودة ب افضل سعر و كله كويس",
  },
  {
    img: av,
    name: "ماجد الشافعي",
    rev: "نيسكت ليفل من افضل شركات مصر و بشمهندس أحمد سمير راجل جدع و رجولة و التيم مية مية احسن شغل و اعلي جودة ب افضل سعر و كله كويس",
  },
  {
    img: av,
    name: "ماجد الشافعي",
    rev: "نيسكت ليفل من افضل شركات مصر و بشمهندس أحمد سمير راجل جدع و رجولة و التيم مية مية احسن شغل و اعلي جودة ب افضل سعر و كله كويس",
  },
  {
    img: av,
    name: "ماجد الشافعي",
    rev: "نيسكت ليفل من افضل شركات مصر و بشمهندس أحمد سمير راجل جدع و رجولة و التيم مية مية احسن شغل و اعلي جودة ب افضل سعر و كله كويس",
  },
  {
    img: av,
    name: "ماجد الشافعي",
    rev: "نيسكت ليفل من افضل شركات مصر و بشمهندس أحمد سمير راجل جدع و رجولة و التيم مية مية احسن شغل و اعلي جودة ب افضل سعر و كله كويس",
  },
  {
    img: av,
    name: "ماجد الشافعي",
    rev: "نيسكت ليفل من افضل شركات مصر و بشمهندس أحمد سمير راجل جدع و رجولة و التيم مية مية احسن شغل و اعلي جودة ب افضل سعر و كله كويس",
  },
  {
    img: av,
    name: "ماجد الشافعي",
    rev: "نيسكت ليفل من افضل شركات مصر و بشمهندس أحمد سمير راجل جدع و رجولة و التيم مية مية احسن شغل و اعلي جودة ب افضل سعر و كله كويس",
  },
];
