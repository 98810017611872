import React from "react";
import { FaCheck } from "react-icons/fa";

const Packages = ({ data }) => {
  return (
    <div id="pack" className="container mx-auto px-8 md:px-16 my-12">
      <p className="mb-8 text-center text-white font-extrabold text-xl md:text-2xl lg:text-3xl xl:text-4xl">
        الباقات
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {data.map((item, index) => (
          <div
            key={index}
            className=" border border-gray-600 rounded-md duration-500 hover:border hover:border-purpleColor p-6"
          >
            <p className="mb-6 rounded-2xl flex items-center justify-center border border-white p-3 text-white bg-[#333] w-fit">
              {item.name}
            </p>
            <p className="text-purpleColor font-bold mb-4 text-xl md:text-2xl lg:text-3xl xl:text-4xl">
              {item.price} $ /{" "}
              <span className=" text-sm text-gray-400 font-normal">
                في الشهر
              </span>
            </p>
            <p className="text-gray-400 text-sm  pb-8  border-b border-b-gray-600">
              {item.desc}
            </p>
            <div className="my-4">
              {item.details.map((itemTwo, indexTwo) => (
                <div key={indexTwo} className="flex items-center gap-2 mb-1">
                  <FaCheck size={15} className=" text-purpleColor" />
                  <p className="text-white">{itemTwo}</p>
                </div>
              ))}
            </div>
            <button className="flex items-center justify-center py-3 px-6 text-white bg-purpleColor w-[220px] mx-auto rounded-lg">
              اشترك الان
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Packages;
