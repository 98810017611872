import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { IoIosArrowRoundUp } from "react-icons/io";
const FixedBtn = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 90) {
        setShow(true);
      } else {
        setShow(false);
      }
    };
    document.addEventListener("scroll", handleScroll);
  }, []);
  return (
    <div>
      {/*whats*/}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://wa.me/+201022153359"
        className=" fixed right-4 bottom-24 z-50 w-14 h-14 rounded-[50%] p-3 flex items-center justify-center text-white bg-[#25d366] "
      >
        <FaWhatsapp size={30} />
      </a>

      {/**scroll to top*/}
      <div
        onClick={() =>
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          })
        }
        className={`fixed duration-500 ${
          show ? "right-4" : "right-[-300%]"
        } bottom-4 z-50 w-14 h-14 rounded-[50%] p-3 flex items-center justify-center cursor-pointer text-white bg-purpleColor`}
      >
        <IoIosArrowRoundUp size={30} />
      </div>
    </div>
  );
};

export default FixedBtn;
