import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
const Statistics = ({ data }) => {
  const [count, setCount] = useState(data.map(() => 0));
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once when section becomes visible
  });
  useEffect(() => {
    const incrementAutomatically = () => {
      if (inView) {
        setCount((prevCounters) =>
          prevCounters.map((count, index) =>
            count < data[index].number ? count + 1 : count
          )
        );
      }
    };

    const interval = setInterval(incrementAutomatically, 50);
    return () => {
      clearInterval(interval);
    };
  }, [inView, data]);
  return (
    <div className="container mx-auto px-8 md:px-16 my-8">
      <div ref={ref} className="grid grid-cols-2 md:grid-cols-4 gap-6">
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              backgroundColor: item.bg,
            }}
            className="p-4 rounded-lg flex flex-col items-center justify-center gap-4"
          >
            <p className="font-bold text-xl md:text-3xl">{count[index]}</p>
            <p className=" font-medium">{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Statistics;
