import React from "react";

const Portfolio = ({ data }) => {
  return (
    <div id="port" className="container mx-auto px-8 md:px-16 my-12">
      <p className="mb-8 text-center text-white font-extrabold text-xl md:text-2xl lg:text-3xl xl:text-4xl">
        أعمالنا
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {data.slice(0, 6).map((item, index) => (
          <div
            key={index}
            className="overflow-hidden relative box cursor-pointer"
          >
            <img
              alt={item.title}
              src={item.img}
              className="w-full h-[200px] md:h-[350px] object-cover"
            />
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
              className="absolute inset-0 flex items-center justify-center overlay p-4 rounded-3xl duration-500"
            >
              <p className="text-white font-bold text-xl md:text-2xl">
                {item.title}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
