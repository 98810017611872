import React from "react";
import Nav from "./components/layout/Nav";
import {
  links,
  team,
  stats,
  reviews,
  featuers,
  marketing,
  packages,
  portfolio,
  specialistDetails,
  blogs,
} from "./fakers/data";
import Hero from "./components/Hero";
import About from "./components/About";
import Statistics from "./components/Statistics";
import Reviews from "./components/Reviews";
import Footer from "./components/layout/Footer";
import Featuers from "./components/Featuers";
import MovingBar from "./components/MovingBar";
import Contact from "./components/Contact";
import Specialist from "./components/Specialist";
import Packages from "./components/Packages";
import Portfolio from "./components/Portfolio";
import FixedBtn from "./components/utils/FixedBtn";
import Blogs from "./components/Blogs";
const App = () => {
  return (
    <div>
      <FixedBtn />
      <Nav links={links} />
      <Hero />
      <About data={team} />
      <Statistics data={stats} />
      <Featuers data={featuers} />
      <MovingBar data={marketing} />
      <Portfolio data={portfolio} />
      <Specialist data={specialistDetails} />
      <Packages data={packages} />
      <Blogs data={blogs} />
      <Reviews data={reviews} />
      <Contact />
      <Footer links={links} />
    </div>
  );
};

export default App;
