import React from "react";
import logo from "../../assets/Next Level logo -01.png";
import Social from "../utils/Social";

const Footer = ({ links }) => {
  return (
    <div className="footer pb-6 pt-12">
      <div className="container mx-auto px-8 md:px-16">
        <div className="flex justify-between flex-wrap">
          <div className="mb-4 lg:mb-0">
            <img alt="logo" src={logo} className=" h-16 object-cover mb-8" />
            <p className=" text-textColor mb-4">أكثر من مجرد لعبة</p>
            <Social />
          </div>
          <div className="mb-4 lg:mb-0">
            <p className="font-bold text-lg md:text-xl text-white mb-8">
              روابط هامة
            </p>
            <ul>
              {links.map((link, index) => (
                <li key={index} className="mb-3">
                  <a href={link.path} className="text-white">
                    {link.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <p className="font-bold text-lg md:text-xl text-white mb-8">
              تواصل معنا
            </p>
            <ul>
              <li className="mb-3">
                <a
                  className="text-white"
                  href="https://nxtlvladv.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  رابط الموقع
                </a>
              </li>
              <li className="mb-3">
                <a
                  className="text-white"
                  href="https://wa.me/+201022153359"
                  target="_blank"
                  rel="noreferrer"
                >
                  01022153359
                </a>
              </li>
              <li className="mb-3">
                <a
                  className="text-white lowercase"
                  href="mailto:magedelshafey98@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  magedelshafey98@gmail.com
                </a>
              </li>
              <li className=" text-white">المنصورة عبد السلام عارف</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
