import React from "react";

const Hero = () => {
  return (
    <div id="about" className="container mx-auto px-8 md:px-16 mt-36">
      <div className="flex justify-between flex-col md:flex-row gap-6">
        <div className="md:w-1/2">
          <p className=" font-extrabold text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-white md:w-[75%] mb-3">
            لديك عمل، لدينا عقول رائعة
          </p>
        </div>
        <div className="md:w-1/2 flex flex-col items-center">
          <p className="md:w-[75%] text-purpleColor text-center mb-6">
            تأسست عام 2022 من قبل فريق من المطورين والمصممين والمتخصصين
            المحترفين
          </p>
          <div className="flex items-center justify-center flex-wrap gap-6">
            <button className="bg-white flex items-center justify-center py-4 px-6 text-black rounded-md min-w-[150px]">
              ابدأ الان
            </button>
            <button className="flex items-center justify-center py-4 px-6 rounded-md min-w-[150px] text-purpleColor border border-purpleColor ">
              مشاهدة الفيديو
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
