import React, { useEffect, useState } from "react";
import logo from "../../assets/Next Level logo -01.png";
import { MdOutlineMenu } from "react-icons/md";
import { IoMdClose } from "react-icons/io";

const Nav = ({ links }) => {
  const [border, setBorder] = useState(false);
  const [sideBar, setSidebar] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 90) {
        setBorder(true);
      } else {
        setBorder(false);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={`fixed top-0 left-0 right-0 w-screen z-40 ${
          border ? "bb bg-black" : null
        }`}
      >
        <div className="container mx-auto px-8 md:px-16 py-6">
          <div className="flex items-center justify-between">
            {/*links*/}
            <ul className="hidden flex-1 md:flex items-center gap-6">
              {links.map((item, index) => (
                <li key={index}>
                  <a
                    href={item.path}
                    className="text-white font-medium duration-500 hover:text-purpleColor"
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
            <div className="md:hidden">
              <MdOutlineMenu
                onClick={() => setSidebar(true)}
                size={30}
                className=" cursor-pointer text-white duration-500 hover:text-purpleColor"
              />
            </div>
            <a href="/#">
              <img alt="logo" src={logo} className=" h-10  object-cover" />
            </a>
          </div>
        </div>
      </div>
      {/**sidebar*/}
      <div
        className={` z-50 duration-500 fixed w-screen bg-blueColor top-0 ${
          sideBar ? "left-0" : "left-[-300%]"
        }`}
      >
        <div className="p-5">
          <IoMdClose
            size={20}
            className="text-white cursor-pointer mb-8 "
            onClick={() => setSidebar(false)}
          />
          <ul className="flex items-center justify-center flex-col  gap-6">
            {links.map((item, index) => (
              <li key={index}>
                <a
                  href={item.path}
                  className="text-white font-medium duration-500 hover:text-purpleColor"
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Nav;
