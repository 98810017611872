import React from "react";

const About = ({ data }) => {
  return (
    <div className="container mx-auto px-8 md:px-16 my-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {data.map((item, index) => (
          <img
            key={index}
            alt="about"
            src={item}
            className="h-[250px] md:h-[350px] w-full object-cover"
          />
        ))}
      </div>
    </div>
  );
};

export default About;
