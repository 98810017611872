import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaTiktok,
  FaSnapchatGhost,
  FaTwitter,
} from "react-icons/fa";
const Social = () => {
  return (
    <div className="p-4 rounded-xl bg-white flex items-center justify-center gap-3 flex-wrap">
      <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
        <FaFacebook size={30} className=" text-purpleColor" />
      </a>
      <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
        <FaInstagram size={30} className=" text-purpleColor" />
      </a>
      <a href="https://www.tiktok.com" target="_blank" rel="noreferrer">
        <FaTiktok size={30} className=" text-purpleColor" />
      </a>
      <a href="https://www.snapchat.com" target="_blank" rel="noreferrer">
        <FaSnapchatGhost size={30} className=" text-purpleColor" />
      </a>
      <a href="https://www.x.com" target="_blank" rel="noreferrer">
        <FaTwitter size={30} className=" text-purpleColor" />
      </a>
    </div>
  );
};

export default Social;
