import React from "react";

const Featuers = ({ data }) => {
  return (
    <div id="feat" className="container mx-auto px-8 md:px-16 my-12">
      <p className="text-center text-white mb-8 font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl">
        لماذا NEXT LEVEL
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {data?.map((item, index) => (
          <div key={index} className=" bg-[#333] p-4 rounded-md">
            <div className="w-16 h-16 rounded-[50%] p-2 flex items-center justify-center bg-[#444]">
              <p
                style={{
                  color: item.bg,
                }}
              >
                {item.img}
              </p>
            </div>
            <p className="text-white font-medium my-4">{item.title}</p>
            <p className=" text-slate-300">{item.desc}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Featuers;
