import React from "react";

const MovingBar = ({ data }) => {
  return (
    <div className="main py-5 overflow-x-hidden">
      <div className="container mx-auto px-8 md:px-16 moving-container">
        <div className="flex justify-between items-center flex-wrap  gap-2 md:gap-0 ">
          {data.map((item, index) => (
            <div key={index} className="flex items-center gap-2">
              <p className="text-white font-medium">{item.title}</p>
              <p className="text-white">{item.icon}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MovingBar;
