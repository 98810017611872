import React from "react";
import meeting from "../assets/meet.jpg";
const Specialist = ({ data }) => {
  return (
    <div className="footer2 py-8">
      <div className="container mx-auto px-8 md:px-16 my-12">
        <div className="flex flex-col md:flex-row items-center gap-8">
          <div className="md:w-1/2">
            <p className="text-purpleColor font-extrabold text-x1 md:text-2xl lg:text-3xl xl:text-4xl mb-3">
              التخصص في مساعدة العملاء على التحديات المالية
            </p>
            <p className=" text-sm mb-5 text-white">
              لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل
              ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه … بروشور
              او فلاير على سبيل المثال … او نماذج مواقع انترنت … وعند موافقه
              العميل المبدئيه على التصميم يتم ازالة هذا النص من التصميم ويتم وضع
              ت للتصميم الاساسي.
            </p>
            <div>
              {data.map((item, index) => (
                <div key={index} className="flex items-center gap-4 mb-3">
                  <div className=" w-14 h-14 p-3 flex items-center justify-center bg-black rounded-[50%]">
                    <p className="text-[#fcbd16]">{item.icon}</p>
                  </div>
                  <div>
                    <p className="font-bold text-lg md:text-xl lg:text-2xl mb-3 text-[#fcbd16]">
                      {item.title}
                    </p>
                    <p className="text-sm text-white">{item.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="md:w-1/2">
            <img alt="fff" src={meeting} className="w-full object-cover" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Specialist;
