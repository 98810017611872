import React from "react";

const Contact = () => {
  return (
    <div id="contact" className="container mx-auto px-8 md:px-16 my-12">
      <div className="bg-[#fdaa40] flex  items-center justify-center py-7 px-9">
        <div className="w-full flex flex-col md:flex-row gap-6 justify-between items-center">
          <div className="md:w-3/4">
            <p className="font-bold text-md md:text-lg lg:text-xl xl:text-2xl mb-3">
              احصل على تحليل تسويقي مجاني
            </p>
            <p className=" text-slate-900 ">
              لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل
              ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه … بروشور
              او فلاير على سبيل المثال … او نماذج مواقع انترنت … وعند موافقه
              العميل المبدئيه على التصميم يتم ازالة هذا النص من التصميم ويتم وضع
              النصوص النهائية المطلوبة للتصميم ويقول البعض ان وضع النصوص
              التجريبية بالتصميم قد تشغل المشاهد عن وضع الكثير من الملاحظات او
              الانتقادات للتصميم الاساسي. وخلافاَ للاعتقاد السائد فإن لوريم
              إيبسوم ليس نصاَ عشوائياً، بل إن له جذور في الأدب اللاتيني
              الكلاسيكي منذ العام 45 قبل الميلاد. من كتاب “حول أقاصي الخير
              والشر”
            </p>
            <p></p>
          </div>
          <div className="md:w-1/4 flex md:justify-end">
            <button className="bg-black text-white p-4 flex items-center justify-center">
              تواصل معنا
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
