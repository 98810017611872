import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Reviews = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true, // Enable autoplay
    speed: 100,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    verical: false,
    slidesToScroll: 2,
    initialSlide: 0,
    cssEase: "linear",
    verticalSwiping: false,
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div id="rev" className="container mx-auto px-8 md:px-16 my-12">
      <p className=" font-bold text-center text-xl md:text-2xl lg:text-3xl xl:text-4xl text-white mb-6">
        اراء العملاء
      </p>
      <Slider {...settings}>
        {data.map((item, index) => (
          <div
            style={{ margin: "0 10px" }}
            key={index}
            className=" bg-[#222] p-5 relative rounded-xl border border-purpleColor"
          >
            <img
              alt={item.name}
              src={item.img}
              className="absolute left-[50%] translate-x-[-50%] top-2 w-40 h-40 rounded-[50%]  object-cover "
            />
            <div className=" mt-40">
              <p className="text-white font-bold text-center mb-6">
                {item.name}
              </p>
              <p className=" text-textColor text-center">{item.rev}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Reviews;
